import { ReactNode } from "react";
import Skeleton from "src/components/Skeleton";
import { cn } from "src/utilities/shadcnUtils";

export const KeyValue = ({
  label,
  value,
  loading,
  className,
}: { label: string; value?: string | ReactNode; loading?: boolean; className?: string }) => (
  <div className={cn("flex flex-row items-center justify-between p-3 rounded", className)}>
    <p className="text-sm font-bold mr-2">{label}:</p>
    {loading ? (
      <Skeleton width={160} />
    ) : typeof value === "string" ? (
      <p className="text-sm text-right text-ellipsis line-clamp-2">{value || "N/A"}</p>
    ) : (
      value
    )}
  </div>
);
