import { formatters, selectors } from "@fraction/shared";
import { useParams } from "react-router-dom";
import { ApplicantOverview } from "src/apps/BrokerDashboard/components/ApplicantOverview";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { getCurrentStatusBlock } from "src/components/AppStatusBadge";
import { BackButton } from "src/components/BackButton";
import { DocumentChecklist } from "src/components/DocumentChecklist";
import { FundingProgress } from "src/components/FundingProgress";
import { TaskChecklist } from "src/components/TaskChecklist";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationAuthed } from "src/hooks/useApplication";

export const AppOverview = () => {
  const { id } = useParams();

  const { data: app, isLoading: loading } = useApplicationAuthed({
    id,
  });

  const title = app?.id
    ? `${formatters.application.applicantNames(app)} | ${
        app?.status ? getCurrentStatusBlock(app?.status)?.label : "Fraction"
      }`
    : "App details";

  useDocumentTitle(title);

  const isActiveStatus = selectors.isActiveDeal(app || {});
  return (
    <div className="p-6 flex flex-col max-w-full gap-y-2">
      <BackButton to={isActiveStatus ? "/" : "/closed"} className="mb-2" label={title} />
      <ApplicantOverview app={app || undefined} loading={loading} />
      <div className="gap-2 flex flex-col lg:flex-row max-w-full flex-wrap">
        <ApplicationOverview className="md:min-w-[450px] flex-1" loading={loading} app={app || undefined} />
        <DocumentChecklist
          className="lg:max-w-3xl max-w-full md:min-w-[450px] flex-1"
          applicationId={app?.id}
        />
        <div className="gap-2 flex flex-col lg:max-w-3xl max-w-full md:min-w-[450px] flex-1">
          <FundingProgress
            rejectReason={app?.declineReason}
            loading={loading}
            status={app?.status}
            className=" max-w-full"
          />
          <TaskChecklist applicationId={app?.id} className="max-w-full max-h-[517px] overflow-y-scroll" />
        </div>
      </div>
    </div>
  );
};
