import { formatters } from "@fraction/shared";
import _ from "lodash";
import { ApplicantOverview } from "src/apps/BrokerDashboard/components/ApplicantOverview";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { DocumentChecklist } from "src/components/DocumentChecklist";
import { FundingProgress } from "src/components/FundingProgress";
import Skeleton from "src/components/Skeleton";
import { TaskChecklist } from "src/components/TaskChecklist";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationsQuery } from "src/hooks/useApplication";

export const PrefundedDashboardOverview = () => {
  const { data: apps, isLoading } = useApplicationsQuery({ accountType: "applicant", status: "active" });
  const app = _.minBy(apps, "createdAt");

  useDocumentTitle("Loan overview | Fraction");

  return (
    <div className="p-8 flex flex-col max-w-full gap-y-2">
      <h1 className="text-3xl font-serif-deck">Your Fraction application</h1>
      {isLoading ? (
        <Skeleton width={500} height={24} />
      ) : (
        <h2 className="text-xl font-bold">{formatters.property.formattedAddress(app?.property)}</h2>
      )}
      <p className="mb-2">View details about your application, upload documents, and complete tasks.</p>
      <FundingProgress
        className="flex-auto max-w-full min-w-[300px] md:min-w-[400px] h-fit"
        rejectReason={app?.declineReason}
        status={app?.status}
      />
      <div className="flex flex-row gap-2 lg:flex-row max-w-full flex-wrap">
        <ApplicantOverview
          className="w-fit flex-row flex-wrap h-fit"
          app={app || undefined}
          loading={isLoading}
        />
        <TaskChecklist className="flex-1 max-w-full min-w-[300px] md:min-w-[550px]" applicationId={app?.id} />
        <DocumentChecklist
          className="flex-1 max-w-full min-w-[300px] md:min-w-[550px]"
          applicationId={app?.id}
        />
        <ApplicationOverview
          className="flex-1 max-w-full min-w-[300px] md:min-w-[400px]"
          loading={isLoading}
          app={app || undefined}
        />
      </div>
    </div>
  );
};
