import { calculators, documents, entities, enums, formatters, selectors, types } from "@fraction/shared";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import _ from "lodash";
import { CheckCircle, Clock, DownloadCloud, Loader, Trash } from "lucide-react";
import { useCallback } from "react";
import { useDeleteDocuments } from "src/apps/PostFundedDashboard/queries";
import { useChecklist } from "src/apps/PreFundedDashboard/hooks/checklist";
import Chip from "src/components/Chip";
import Dropzone, { FileWithEnumType } from "src/components/Dropzone";
import Skeleton from "src/components/Skeleton";
import Tooltip from "src/components/Tooltip";
import { Badge } from "src/components/ui/badge";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { useApplicationAuthed } from "src/hooks/useApplication";
import { useUploadDocuments } from "src/hooks/useSimpleUploadDocuments";
import { Document } from "src/images";
import { useMutation } from "src/lib";
import { cn } from "src/utilities/shadcnUtils";

function DocumentRow({
  item,
  allDebts,
  applicationId,
  onUploadSuccess,
  canUpload,
}: {
  item: types.DocumentChecklistItem;
  allDebts: entities.Debt[];
  applicationId: string;
  onUploadSuccess?: () => Promise<any>;
  canUpload?: boolean;
}) {
  const debt = item?.debtId ? allDebts?.find((debt) => debt.id === item?.debtId) : null;
  const refetchFromDeleteMutation = useMutation({
    mutationFn: onUploadSuccess,
  });
  const refetchFromUploadMutation = useMutation({
    mutationFn: onUploadSuccess,
  });
  const { mutateAsync: uploadDocuments, isPending: uploadPending_ } = useUploadDocuments({
    onUploadSuccess: refetchFromUploadMutation.mutateAsync,
  });
  const { mutateAsync: deleteDocument, isPending: deletePending_ } = useDeleteDocuments({
    onSuccess: refetchFromDeleteMutation.mutateAsync,
  });

  const deletePending = deletePending_ || refetchFromDeleteMutation.isPending;
  const uploadPending = uploadPending_ || refetchFromUploadMutation.isPending;

  const onClickDelete = useCallback(async () => {
    if (!item.id) {
      throw new Error("No document id");
    }
    deleteDocument(item.id);
  }, [item?.id]);

  const handleDropDocument = useCallback(
    (docs: FileWithEnumType[]) => {
      if (!item?.document) {
        throw new Error("No document type found");
      }
      uploadDocuments({
        assets: docs,
        applicationId,
        documentTypeName: item?.document,
        applicantId: item?.applicantId,
        debtId: item?.debtId,
        incomeId: item?.incomeId,
      });
    },
    [item?.type, applicationId, uploadDocuments, item?.applicantId, item?.debtId, item?.incomeId]
  );

  return (
    <Tooltip
      key={item.id}
      text={
        <div>
          {item?.approval ? (
            <b>Document {formatters.enums.APPROVAL_STATUS_MAP[item?.approval]?.toLowerCase()}</b>
          ) : (
            <b>Waiting for upload</b>
          )}
          <p>{item?.documentDescription}</p>
        </div>
      }
    >
      <div
        key={item.id}
        className="flex flex-col md:flex-row flex-wrap md:items-center justify-between p-3 rounded gap-1 odd:bg-gray-100 bg-white hover:bg-gray-200"
      >
        <div className="md:max-w-[50%]">
          <p>{item.documentName || _.startCase(item.document)}</p>
          {item.applicantName ? (
            <p className="text-sm font-medium text-gray-600">For {item.applicantName}</p>
          ) : null}
          {item.debtWithWho ? (
            <p className="text-sm font-medium text-gray-600">
              For {item.debtWithWho || item.debtDescription || ""}{" "}
              {item.debtType
                ? formatters.enums.DEBT_TYPE_MAP[item.debtType as enums.DebtType]?.toLowerCase()
                : ""}
              {debt?.currentlyOwing
                ? ` (${formatters.number.getCurrencyFromNumber(debt.currentlyOwing)})`
                : ""}
            </p>
          ) : null}
          {item?.notes ? <p className="mt-1 bg-gray-400 p-1.5 px-2 text-sm rounded"><b>Notes:</b> {item.notes}</p> : null}
        </div>
        <div className="flex flex-row gap-x-1">
          {!deletePending ? (
            <>
              <Dropzone
                accept={[types.MimeType.PDF, types.MimeType.PNG, types.MimeType.JPEG, types.MimeType.HEIC]}
                disabled={!canUpload || uploadPending}
                multi
                onDrop={handleDropDocument}
                dragActiveChildren={
                  <Badge className="w-full" variant="default" loading={uploadPending}>
                    Drag file here
                  </Badge>
                }
              >
                <Badge
                  className="w-full"
                  variant={
                    item?.approval === enums.ApplicationTaskApprovalStatus.APPROVED
                      ? "success"
                      : item?.approval === enums.ApplicationTaskApprovalStatus.REQUESTED
                      ? "secondary"
                      : item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED
                      ? "destructive"
                      : item?.status === enums.ApplicationTaskStatus.TODO && !uploadPending
                      ? "default"
                      : "default"
                  }
                >
                  {item?.approval === enums.ApplicationTaskApprovalStatus.APPROVED ? (
                    <CheckCircle height={14} className="text-black" />
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.REQUESTED ? (
                    <Clock height={14} className="text-black" />
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED ? (
                    <CrossCircledIcon height={14} className="text-white mr-1" />
                  ) : null}
                  {uploadPending ? (
                    <>
                      <Loader height={14} className="text-white animate-spin" /> Uploading...
                    </>
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED ? (
                    "Rejected"
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK ? (
                    "Needs work"
                  ) : item.status === enums.ApplicationTaskStatus.COMPLETE ? (
                    "Uploaded"
                  ) : (
                    "Upload doc"
                  )}
                </Badge>
              </Dropzone>
              {item?.documentUrl ? (
                <a href={item.documentUrl}>
                  <Badge className="w-full" variant="default">
                    <DownloadCloud height={12} />
                  </Badge>
                </a>
              ) : null}
            </>
          ) : null}
          {item?.documentUrl ? (
            <button disabled={deletePending} onClick={onClickDelete}>
              <Badge variant="destructive">{!deletePending ? <Trash height={12} /> : "Deleting..."}</Badge>
            </button>
          ) : null}
        </div>
      </div>
    </Tooltip>
  );
}

export function DocumentChecklist({
  applicationId,
  onClickComplexUpload,
  className,
  status,
}: {
  applicationId?: string;
  onClickComplexUpload?: () => void;
  className?: string;
  status?: enums.ApplicationStatus;
}) {
  const { data: app, isLoading } = useApplicationAuthed({
    id: applicationId,
  });

  const {
    data: checklist,
    refetch: refetchChecklist,
    isLoading: checklistLoading,
  } = useChecklist({
    id: applicationId,
    status: app && selectors.isInactiveDeal(app) ? app?.status : enums.ApplicationStatus.READY_FOR_DRAW,
  });

  const { preference } = useAccountViewPreference();

  const allDebts = app ? calculators.application.selectAllDebts(app) : [];

  const docChecklist = checklist
    ?.filter(types.isDocumentChecklistItem)
    ?.filter((item) => item.status !== enums.ApplicationTaskStatus.NOT_APPLICABLE)
    ?.filter((item) => documents.shouldShowDocumentChecklistItem(item, preference));

  return (
    <div className={cn("border p-6 border-gray-400 rounded max-h-[700px] overflow-y-scroll", className)}>
      <div className="flex flex-row justify-between items-center mb-3">
        <p className="text-2xl font-bold">Document checklist</p>

        {onClickComplexUpload ? (
          <button onClick={onClickComplexUpload}>
            <Chip className="hover:bg-green-400" variant="green">
              Upload complex
            </Chip>
          </button>
        ) : null}
      </div>
      <p className="text-md mb-3">
        These are the various documents that will need to be collected throughout the application process. If
        you don't know what they are, don't worry, we will reach out when we need each document!
      </p>
      <div className="flex flex-col">
        {(!docChecklist?.length && checklistLoading) || isLoading ? (
          Array.from({ length: 6 }).map((_, i) => <Skeleton className="w-full h-11" key={i} />)
        ) : !docChecklist?.length ? (
          <div className="h-60 w-full flex flex-col items-center justify-center">
            <Document width="100%" height={70} />
            <p className="text-sm text-gray-700 mt-2 font-light text-center">
              No more documents are
              <br /> currently needed for this file
            </p>
          </div>
        ) : (
          docChecklist?.map((item, idx) =>
            applicationId ? (
              <DocumentRow
                onUploadSuccess={refetchChecklist}
                applicationId={applicationId}
                key={idx}
                item={item}
                allDebts={allDebts}
                canUpload={documents.canUploadDocumentChecklistItem(item, preference)}
              />
            ) : null
          )
        )}
      </div>
    </div>
  );
}
