import { entities, enums } from "@fraction/shared";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useMutation, useQuery } from "src/lib";

export const useInvites = () => {
  const { user, isAuthenticated } = useAuth();

  const queryClient = useQueryClient();

  const invitesQ = useQuery({
    queryKey: ["invites", user?.id],
    enabled: isAuthenticated,
    queryFn: () => fraction.getInvites(),
  });

  const sendInviteToApplicant = useMutation({
    mutationFn: async ({
      inviteeId,
      channels,
    }: Partial<entities.Invite> & { channels: enums.ChannelType[] }) => {
      const invite = await fraction.sendInviteToApplicant({ inviteeId, channels });
      if (invite) {
        queryClient.setQueryData<entities.Invite[]>(["invites", user?.id], (prev) =>
          _.uniqBy([...(prev || []), invite], "id")
        );
      }
    },
  });

  return {
    ...invitesQ,
    invites: invitesQ?.data,
    isPending: sendInviteToApplicant.isPending,
    sendInviteToApplicant: sendInviteToApplicant.mutateAsync,
  };
};
